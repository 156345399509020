module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sw-heating-and-sons","short_name":"SW Heating","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","description":"Plumbing, heating and gas services","display":"standalone","icon":"src/images/sw-heating-app-icon.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9b1f83abfd7066c7845aa7530cb50f1b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
